import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import { HTMLContent } from "../components/Content";
import ProductContent from "../components/ProductContent";

const ProfessionalVehiclePaintingPage = ({ data }) => {
    const { markdownRemark: post } = data;

    return (
        <Layout>
            <ProductContent
                contentComponent={HTMLContent}
                title={post.frontmatter.title}
                content={post.html}
            />
        </Layout>
    );
};

ProfessionalVehiclePaintingPage.propTypes = {
    data: PropTypes.object.isRequired
};

export default ProfessionalVehiclePaintingPage;

export const ProfessionalVehiclePaintingPageQuery = graphql`
  query ProfessionalVehiclePaintingPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`;
